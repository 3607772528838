import React, { useState, useEffect } from "react"
import { css, keyframes } from "emotion"

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const Animate = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) {
      setRender(true)
    }
  }, [show])

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false)
    }
  }

  const animation = show ? fadeIn : fadeOut

  return (
    shouldRender && (
      <div
        className={css`
          animation: ${animation} 0.5s;
        `}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  )
}
