import styled from "@emotion/styled"
import React from "react"
import { MdErrorOutline, MdClose } from "react-icons/md"
import { theme } from "../../theme"
import { Alert } from "../basic"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ErrorIcon = styled(MdErrorOutline)`
  font-size: 10rem;
  color: ${theme.colors.error};
`

const Message = styled.div`
  flex: 1;
`

const CloseIcon = styled(MdClose)`
  color: ${theme.colors.secondary};
  cursor: pointer;
`

export const ErrorAlert = ({ show, message, onClose }) => {
  return (
    <Alert type="error" show={show}>
      <Container>
        <ErrorIcon />
        <Message>{message}</Message>
        <CloseIcon onClick={onClose} />
      </Container>
    </Alert>
  )
}
