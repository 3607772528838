import styled from "@emotion/styled"
import { ErrorMessage, Field as FormikField } from "formik"
import React, { memo } from "react"
import { theme } from "../../theme"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledField = styled(FormikField)``

const ErrorBlock = styled.div`
  font-size: ${theme.fontSizes[1]};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary};

  span::before {
    content: "*";
    color: ${theme.colors.error};
    margin-right: 0.25rem;
  }
`

export const Field = memo(props => {
  const { name, label, className } = props

  return (
    <Container className={className}>
      <FieldDetails>
        <label htmlFor={name}>{label}</label>
        <ErrorBlock>
          <ErrorMessage name={name} component="span" />
        </ErrorBlock>
      </FieldDetails>
      <StyledField {...props} />
    </Container>
  )
})
