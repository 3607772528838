import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { IoIosCheckmarkCircleOutline } from "react-icons/io"
import { Alert } from "../components/basic"
import { WorkingGirl } from "../components/characters/working-girl"
import { ContactForm } from "../components/contact-form"
import { Footer, Layout, Page, Row, Copyright } from "../components/layout"
import { SEO } from "../components/seo"
import { PAGE_TYPES } from "../components/seo/schemas"
import { SocialLinks } from "../components/social/social-links"
import { theme } from "../theme"

const ContactPage = styled(Page)`
  background: ${theme.colors.backgroundLight};
`

const Container = styled.div`
  flex: 1;
  display: flex;
`

const ContactFooter = styled(Footer)`
  padding-top: 5rem;
  padding-bottom: 1rem;
  background: linear-gradient(180deg, #60669d 0%, #4c4578 100%);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Columns = styled(Row)`
  flex: 1;
  display: flex;
  padding-bottom: 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    align-self: stretch;
  }

  @media (min-width: 600px) {
    margin-right: 2rem;
  }
`

const Right = styled.div`
  margin-top: 4rem;
  margin-bottom: -2rem;
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Form = styled.div`
  align-self: stretch;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
`

const SuccessIcon = styled(IoIosCheckmarkCircleOutline)`
  font-size: 10rem;
  color: ${theme.colors.textLight};
`

const Back = styled.div`
  display: flex;
  align-items: center;
`

const SuccessAlert = ({ show, contactName }) => {
  return (
    <Alert show={show}>
      <Content>
        <SuccessIcon />
        <p>
          Thank you for your message {contactName}. Will get back to you
          shortly.
        </p>
        <Back>
          <Link to="/">BACK TO HOME</Link>
        </Back>
      </Content>
    </Alert>
  )
}

const Contact = () => {
  return (
    <ContactPage>
      <Layout
        footer={() => (
          <ContactFooter>
            <Copyright />
          </ContactFooter>
        )}
      >
        <SEO title="Contact" seoSchemaOrgType={PAGE_TYPES.CONTACT} />
        <Container>
          <Columns>
            <Left>
              <header>
                <h1>Let's talk</h1>
              </header>

              <Form>
                <ContactForm
                  topContent={({ values }, { isSuccess }) => (
                    <SuccessAlert show={isSuccess} contactName={values.name} />
                  )}
                />
              </Form>

              <SocialLinks />
            </Left>
            <Right>
              <WorkingGirl />
            </Right>
          </Columns>
        </Container>
      </Layout>
    </ContactPage>
  )
}

export default Contact
